'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import { ErrorContent } from '@/ui/components/ErrorContent';

const Error = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error);
    }, [error]);

    return <ErrorContent errorCode={'500'} errorHandler={reset} />;
};

export default Error;
